import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import Property from 'components/properties/Property';
import { List, ListItem } from '@itsa.io/ui';
import getPropertyData from 'api/properties';
import useStyles from 'styles/components/properties/propertyList';
/*
const properties = [
	{
		id: 1,
		address: '425 Piney Way SW',
		city: 'Atlanta',
		state: 'GA',
		zip: 30311,
		price: 200000,
		beds: 4,
		baths: 4,
		mainImage: '/images/buy_a_home.webp',
		type: 'New construction',
	},
	{
		id: 2,
		address: '6458 Stonelake Pl SW',
		city: 'Atlanta',
		state: 'GA',
		zip: 30311,
		price: 375000,
		beds: 4,
		baths: 3,
		sqft: 3073,
		mainImage: '/images/buy_a_home.webp',
		type: 'New construction',
	},
	{
		id: 3,
		address: '412 College Ave, San Francisco',
		city: 'San Francisco',
		state: 'CA',
		zip: 94112,
		price: 2399000,
		beds: 3,
		baths: 2,
		sqft: 2242,
		mainImage: '/images/buy_a_home.webp',
	},
];
*/
const FIRST_URI_CHARACTER = '?id=';

const SPLIT_URI_CHARACTER = '&location=';

const addressUri = ({ address, city, state, zip, id }) =>
	FIRST_URI_CHARACTER +
	encodeURIComponent(id) +
	SPLIT_URI_CHARACTER +
	encodeURIComponent(`${address}-${city}-${state}-${zip}`);

const PropertyList = ({ sellPage }) => {
	const classes = useStyles();
	const baseAddress = sellPage
		? 'sell-a-home-details'
		: 'view-listings-details';
	const [properties, setProperties] = useState([]);

	const loadProperties = async () => {
		const data = await getPropertyData();
		if (data) {
			setProperties(data);
		}
	};

	useEffect(() => {
		loadProperties();
	}, []);

	console.debug('properties', properties);

	const list = properties.map(property => (
		<ListItem
			button
			component="a"
			href={`/${baseAddress}${addressUri(property)}`}
		>
			<Property {...property} key={property.id} sellPage={sellPage} />
		</ListItem>
	));

	return <List className={classes.root}>{list}</List>;
};

PropertyList.propTypes = {
	sellPage: PropTypes.bool.isRequired,
};

export default PropertyList;
