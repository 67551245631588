/* eslint-disable no-async-promise-executor */
import axios from 'axios';
import {
	ENVIRONMENT,
	API_URL_PROPERTIES,
	API_USERNAME,
	API_PASSWORD,
	TEST_API_USERNAME,
	TEST_API_PASSWORD,
} from 'config';

const useTestApi = ENVIRONMENT !== 'production';
const USERNAME = useTestApi ? TEST_API_USERNAME : API_USERNAME;
const PASSWORD = useTestApi ? TEST_API_PASSWORD : API_PASSWORD;

const getPropertyData = async addresses => {
	let propertyData;
	addresses = [
		{ address: '517 N Chugach St', zipcode: '99645' },
		{ address: '12731 Schooner Dr', zipcode: '99515' },
	];
	console.debug('API_URL_PROPERTIES', API_URL_PROPERTIES);
	try {
		// const uri =
		// 	ENVIRONMENT === 'production' ? 'walletversion' : 'wallettestversion';
		const response = await axios({
			method: 'post',
			url: API_URL_PROPERTIES,
			data: addresses,
			withCredentials: true,
			auth: {
				username: USERNAME,
				password: PASSWORD,
			},
		});
		if (response.status === 200 && response.data?.success) {
			propertyData = response.data.data;
		}
	} catch (err) {
		// eslint-disable-next-line no-console
		console.error(err);
		propertyData = null;
	}
	console.debug('propertyData', propertyData);
	return propertyData;
};

export default getPropertyData;
