import { makeStyles, alpha } from '@itsa.io/ui';

export default makeStyles(theme => ({
	root: {
		margin: theme.spacing(0, 0, 4, 0),
		'& .MuiFormLabel-root': {
			color: theme.palette.grey[100],
		},
		'& .MuiInputBase-root, & .MuiInputBase-root:hover': {
			border: `1px solid ${alpha(theme.palette.backgroundExtreme, 0.5)}`,
			backgroundColor: alpha(theme.palette.backgroundExtreme, 0.3),
			'&.Mui-focused': {
				border: `1px solid ${alpha(theme.palette.grey[900], 0.5)}`,
				backgroundColor: alpha(theme.palette.grey[900], 0.3),
			},
		},
		'& .MuiFilledInput-input': {
			fontSize: 16,
			fontWeight: 600,
			color: theme.palette.grey[50],
		},
	},
}));
