import React, { useState } from 'react';
import { useIntl } from '@itsa.io/web3utils';
import { InputAdornment, TextField } from '@itsa.io/ui';
import { Search as SearchIcon } from '@material-ui/icons';
import useStyles from 'styles/components/properties/search';

const Search = () => {
	const { t } = useIntl();
	const [searchValue, setSearchValue] = useState('');
	const classes = useStyles();

	const handleSearchChange = e => {
		setSearchValue(e.target.value);
	};

	const searchField = (
		<TextField
			className={classes.root}
			label={t('search.placeholder')}
			variant="filled"
			onChange={handleSearchChange}
			value={searchValue}
			InputProps={{
				endAdornment: (
					<InputAdornment position="end">
						<SearchIcon className={classes.searchIcon} />
					</InputAdornment>
				),
				disableUnderline: true,
			}}
			fullWidth
		/>
	);
	return searchField;
};

export default Search;
