import React from 'react';
import PropTypes from 'prop-types';
import { utils } from 'web3';
import { Button } from '@itsa.io/ui';
import { useIntl, formatBN } from '@itsa.io/web3utils';
import useStyles from 'styles/components/properties/property';

const Property = ({
	address,
	city,
	state,
	zip,
	beds,
	baths,
	sqft,
	type,
	mainImage,
	price,
	sellPage,
}) => {
	const classes = useStyles();
	const { t } = useIntl();

	let image;
	if (mainImage) {
		image = <img alt="s" className={classes.image} src={mainImage} />;
	}

	let description = '';
	if (typeof beds === 'number') {
		description += ` | <span className={value}>${beds}</span> bd`;
	}
	if (typeof baths === 'number') {
		description += ` | <span className={value}>${baths}</span> ba`;
	}
	if (typeof sqft === 'number') {
		description += ` | <span className={value}>${sqft}</span> sqft`;
	}
	if (type) {
		description += ` - <span className={value}>${type}</span>`;
	}
	if (description) {
		description = description.substring(3);
		// eslint-disable-next-line react/no-danger
		description = <p dangerouslySetInnerHTML={{ __html: description }} />;
	}

	const location = (
		<p>
			{address}, {city}, {state} {zip}
		</p>
	);

	let askPrice;
	if (price) {
		askPrice = `$${formatBN(utils.toBN(price), {
			assetDigits: 0, // toFiat generates 18 assetDigits
			minDigits: 3,
			decimals: 0,
		})}`;
	}

	let editButton;
	if (sellPage) {
		editButton = (
			<Button className={classes.editButton} onClick={() => {}}>
				{t('properties.manage')}
			</Button>
		);
	}

	const propertyInfo = (
		<div className={classes.propertyInfo}>
			<div className={classes.propertyDesc}>
				{description}
				{location}
				{editButton}
			</div>
			<div className={classes.price}>{askPrice}</div>
		</div>
	);

	return (
		<div className={classes.root}>
			{image}
			{propertyInfo}
		</div>
	);
};

Property.defaultProps = {
	baths: null,
	beds: null,
	mainImage: null,
	price: null,
	sqft: null,
	type: null,
};

Property.propTypes = {
	address: PropTypes.string.isRequired,
	baths: PropTypes.number,
	beds: PropTypes.number,
	city: PropTypes.string.isRequired,
	mainImage: PropTypes.string,
	price: PropTypes.number,
	sqft: PropTypes.number,
	sellPage: PropTypes.bool.isRequired,
	state: PropTypes.string.isRequired,
	type: PropTypes.string,
	zip: PropTypes.number.isRequired,
};

export default Property;
